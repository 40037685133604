(function () {
    'use strict';

    angular.module('app').factory('annotation.service', ['$confirm', '$uibModal', '$q', 'api.factory', 'file.service', function ($confirm, $uibModal, $q, _api, _file) {
        return {
            dialog: function (feature) {

                // Dialog
                return $uibModal.open({
                    templateUrl: 'app/annotation/annotation.template.html',
                    controller: ['$rootScope', '$scope', '$uibModalInstance', function ($rootScope, $scope, $uibModalInstance) {

                        // Date
                        $scope.date = {
                            dateFormat: $rootScope.settings.dateFormat,
                            dateTimeFormat: $rootScope.settings.dateFormat + ' HH:mm'
                        };

                        // Add files
                        $scope.add = function () {
                            _file.add().then(function (file) {
                                // Push file onto array
                                $scope.files.push(file);                                
                                // Update form status
                                $scope.frm.$setPristine();
                                $scope.frm.$setDirty();
                            });
                        }

                        // Remove file
                        $scope.remove = function (file) {
                            // Pop file from array
                            $scope.files.pop(file);
                            // Update form status
                            $scope.frm.$setPristine();
                            $scope.frm.$setDirty();
                        }

                        // Feature
                        $scope.feature = feature;
                        
                        // Handle
                        $scope.handle = $scope.feature.get('Handle');

                        // DisplayFields
                        $scope.displayFields = angular.copy($scope.feature.get('DisplayFields'));                        
                        
                        // Files
                        $scope.files = angular.copy($scope.feature.get('Files'));
 
                        // Loading
                        $scope.loading = false;
                       
                        // Save
                        $scope.save = function () {

                            // Save data
                            var displayFields = angular.copy($scope.displayFields)
                            $scope.displayFields = null;

                            var files = angular.copy($scope.files);
                            $scope.files = null;

                            // Close
                            $uibModalInstance.close({ displayFields: displayFields, files: files });
                        };

                        // Cancel
                        $scope.cancel = function () {
                            // Clear displayFields
                            $scope.displayFields = [];
                            // Redirect to default path
                            $uibModalInstance.dismiss('cancel');
                        };

                        // Initialize
                        $scope.init = function () {
                            
                            // Only load data if 
                            if ($scope.handle > -1 && !$scope.feature.get('Dirty')) {
                                
                                // Loading
                                $scope.loading = true;

                                // Load values
                                var $q1 = _api.getObjectInfoValues($scope.handle, _.map($scope.displayFields, 'Name')).then(function (values) {
                                    _.each(values, function (value, index) {
                                        if (!_.isUndefined($scope.displayFields[index]))
                                            $scope.displayFields[index].Value = value;
                                    });
                                });

                                // Load files
                                var $q2 = _api.getObjectFiles($scope.handle).then(function (files) {                                    
                                    $scope.files = files;
                                });
                                
                                $q.all([$q1, $q2]).finally(function () {
                                    $scope.loading = false;
                                });
                            }
                        };

                        // Start initialize
                        $scope.init();
                    }],
                    backdrop: 'static',
                    size: 'sm',
                    windowClass: 'annotation-dialog'
                });
            }
        }
    }]);

})();