(function () {

    // Strict
    'use strict';

    // Controller
    angular.module('app').controller('toolbar.draw.controller', ['$rootScope', '$scope', '$filter', '$localStorage', '$timeout', '$uibModal', '$q', 'toastr', 'map.factory', 'db.factory', 'api.factory', 'geometry.factory', 'log.factory', 'modal.service', 'sync.service', 'annotation.service', 'gps.service', function ($rootScope, $scope, $filter, $localStorage, $timeout, $uibModal, $q, toastr, _map, _db, _api, _geometry, _log, _modal, _sync, _annotation, _gps) {

        var dialog = null;

        // State
        $scope.state = null;

        // Vector layer
        $scope.vector = null;

        // Layer
        $scope.layer = null;

        // Spec
        $scope.spec = null;

        // List of available specs based on layer
        $scope.specs = [];

        // Selected features
        $scope.selected = [];

        // Map singleclick
        $scope.singleclick = null;

        // Select event when select/modify
        $scope.selectedEvent = null;

        // Move to GPS position
        $scope.moveToGpsPosition_ = false;

        // Interaction
        $scope.interactions = {
            'draw': null,
            'select': null,
            'snap': null
        };

        // Buttons
        $scope.buttons = {
            'select': true,
            'commit': false,
            'dismiss': false,
            'delete': false,
            'annotation': false,
            'moveToGps': false,
            'gpsTrack': false
        };

        // Available layers
        $scope.layers = [];

        // Select spec
        $scope.selectSpec = function ($event, spec) {
            // Set spec
            $scope.spec = spec;
        }

        // Select layer
        $scope.selectLayer = function ($event, layer) {
            // Is layer editable?
            if (!layer.IsEditable) {
                $event.stopPropagation();
                $event.preventDefault();
                return false;
            }
            // Set layer
            $scope.layer = layer;

            // Vector layer
            $scope.vector = _map.layers.vector($scope.layer.CdoId);

            // Set buttons state
            $scope.setButtons();

            // Default select
            $scope.select();
        }

        // Any geometry dirty update buttons
        $rootScope.$on('application:tasks:dirty', function () {
            $scope.setButtons();
        });

        // Set buttons state
        $scope.setButtons = function () {

            // Any dirty?
            _geometry.dirty().then(function (geometries) {
                // Commit
                $scope.buttons.commit = geometries.length > 0;
                // Dismiss only new added geometry
                $scope.buttons.dismiss = geometries.length > 0;
            });

            // Modify/Select
            if (!_.isNull($scope.layer)) {
                // Only enable 'modify' if any feature is available
                $scope.buttons.modify = _.filter($scope.vector.getSource().getFeatures(), function (feature) {
                    return feature.get('CdoId') == $scope.layer.CdoId;
                }).length > 0;
            }
        }

        // Select interaction
        $scope.select = function () {

            // Stop interactions
            $scope.stop();

            // State
            $scope.state = 'select';

            // Add snap interaction
            if ($rootScope.settings.drawUseSnap) {
                $scope.interactions.snap = new ol.interaction.Snap({
                    features: new ol.Collection(_.flatten(_.map(_.reject($scope.layers, { CdoId: 0 }), function (layer) {
                        return _map.layers.vector(layer.CdoId).getSource().getFeatures();
                    })))
                });
            }

            // Add select interaction
            $scope.interactions.select = new ol.interaction.Select({
                layers: [$scope.vector],
                multi: false,
                style: (function () {
                    return function (feature, resolution) {

                        // Variabele
                        var lineColor = '#0099FF';
                        var fillColor = '#ffffff';
                        var specId = feature.get('SpecId');
                        var cdoId = feature.get('CdoId');

                        // Return style
                        return _map.layers.style(feature, specId, cdoId, lineColor, fillColor);
                    };
                })()
            });

            $scope.interactions.select.getFeatures().on('add', function (event) {
                event.element.on('change', function (e) {
                    $scope.selected = [event.element];
                    event.element.set('dirty', true);
                });
            });

            // Add modify interaction
            $scope.interactions.modify = new ol.interaction.Modify({
                deleteCondition: ol.events.condition.never,
                features: $scope.interactions.select.getFeatures()
            });

            // Modify end
            $scope.interactions.modify.on('modifyend', function (event) {
                // Set event with coordinate
                $scope.selectedEvent = { coordinate: event.mapBrowserEvent.coordinate };
                // Find geometry in db.
                _.each(event.features.getArray(), function (feature) {
                    var dirty = feature.get('dirty');
                    if (!_.isUndefined(dirty) && dirty == true) {
                        // Add to DB
                        _geometry.updateIndexedDB(feature).then(function () {
                            // Update dirty
                            $rootScope.$broadcast('application:tasks:dirty');
                        });
                    }
                });
            });

            // Add move interaction to map
            $scope.interactions.select.setActive(false);
            _map.map.addInteraction($scope.interactions.select);
            _map.map.addInteraction($scope.interactions.modify);

            // Add interaction snap
            if (!_.isNull($scope.interactions.snap))
                _map.map.addInteraction($scope.interactions.snap);

            // Click on map
            $scope.singleclick = _map.map.on('singleclick', $scope.onMapSingleClick);
        }

        // Map click event 'SingleClick'
        $scope.onMapSingleClick = function (event) {

            // Nothing selected
            $scope.selected = [];

            // Remove previous features
            $scope.interactions.select.getFeatures().clear();

            // LineString en MultiLineString only
            var feature = null;
            if ($scope.layer.TypeName == "LineStyle" || $scope.layer.TypeName == "PolygonStyle") {
                // Closest feature to coordinate
                feature = $scope.vector.getSource().getClosestFeatureToCoordinate(event.coordinate, function (feature) {

                    var tolerance = 10;
                    var geometry = feature.getGeometry();
                    var point = geometry.getClosestPoint(event.coordinate);
                    var pixel = _map.map.getPixelFromCoordinate(point);

                    // Pixel distance within tolerance
                    if (distanceBetweenPixels(pixel, event.pixel) > tolerance)
                        return false;
                    // Feature allowed
                    return true;
                });
            } else {
                // Feature at pixel
                feature = _map.map.forEachFeatureAtPixel(event.pixel, function (feature, layer) {
                    return feature;
                }, null, function (layer) {
                    return layer === $scope.vector;
                });
            }

            // Selected event
            $scope.selectedEvent = event;
            if (!_.isUndefined(feature) && !_.isNull(feature)) {
                // Select feature in control
                $scope.interactions.select.getFeatures().push(feature);
                // Selected feature                
                $scope.selected = [feature];
            }
            // Apply scope
            $scope.$apply();
        };

        // Draw interaction
        $scope.draw = function () {

            if ($scope.state == 'draw') {
                // Stop interactions
                $scope.stop();
                // Enable select
                $scope.select();
                return;
            }

            // Check if there is a selected project. If not fall back on 'DefaultProjectHandle' from settings.js
            if (($localStorage.defaultProject === undefined || $localStorage.defaultProject == null) && ($localStorage.defaultProjectFromTask !== undefined && $localStorage.defaultProjectFromTask == true && ($rootScope.selectedTask === undefined || $rootScope.selectedTask == null))) {
                toastr.warning($filter('translate')('labels.usingdefaultprojecthandlenoproject'));
            }

            // Stop interactions
            $scope.stop();

            // State
            $scope.state = 'draw';

            // Add snap interaction
            if ($rootScope.settings.drawUseSnap) {
                $scope.interactions.snap = new ol.interaction.Snap({
                    features: new ol.Collection(_.flatten(_.map(_.reject($scope.layers, { CdoId: 0 }), function (layer) {
                        return _map.layers.vector(layer.CdoId).getSource().getFeatures();
                    })))
                });
            }

            // Interaction
            $scope.interactions.draw = new ol.interaction.Draw({
                source: $scope.vector.getSource(),
                type: (function () {
                    var type = 'Point';
                    if ($scope.layer.TypeName == 'LineStyle')
                        type = 'LineString';
                    if ($scope.layer.TypeName == 'PolygonStyle')
                        type = 'Polygon';
                    return type;
                })(),
                geometryFunction: function (coordinates, geometry) {

                    if (!_.isUndefined(geometry)) {
                        // Is move to GPS position selected?
                        if ($scope.moveToGpsPosition_) {
                            // GPS position
                            var pos = _gps.position();
                            // Line
                            if ($scope.layer.TypeName == 'LineStyle')
                                coordinates.splice(coordinates.length - 1, 0, pos);
                            // Polygon
                            if ($scope.layer.TypeName == 'PolygonStyle')
                                coordinates[0].splice(coordinates.length - 2, 0, pos);
                            // Stop move to GPS position
                            $scope.moveToGpsPosition_ = false;
                        }
                        geometry.setCoordinates(coordinates);
                    } else {

                        // Detect geometry type
                        if ($scope.layer.TypeName == 'PointStyle')
                            geometry = new ol.geom.Point(coordinates);
                        if ($scope.layer.TypeName == 'LineStyle')
                            geometry = new ol.geom.LineString(coordinates);
                        if ($scope.layer.TypeName == 'PolygonStyle')
                            geometry = new ol.geom.Polygon(coordinates);
                    }
                    return geometry;
                }
            });

            // Draw start
            $scope.interactions.draw.on('drawstart', function (event) {
                // Clear newTask features
                $scope.clearFeaturesForNewTask();
            });

            // Draw end
            $scope.interactions.draw.on('drawend', function (event) {

                // Root 
                var projectHandle = 0;

                // Project from task
                if ($localStorage.defaultProjectFromTask !== undefined && $localStorage.defaultProjectFromTask == true && $rootScope.selectedTask !== undefined && $rootScope.selectedTask != null) {
                    projectHandle = $rootScope.selectedTask.ProjectHandle;
                } else {
                    // Selected project
                    if ($localStorage.defaultProject !== undefined && $localStorage.defaultProject != null) {
                        projectHandle = $localStorage.defaultProject.Handle;
                    } else {
                        // 'DefaultProjectHandle'
                        if ($rootScope.settings.defaultProjectHandle !== undefined && $rootScope.settings.defaultProjectHandle != null) {
                            projectHandle = $rootScope.settings.defaultProjectHandle;
                        }
                    }
                }

                // Log
                _log.error("toolbar.draw", "drawend", "Object created with ProjectHandle: " + projectHandle);

                // Set custom properties
                event.feature.setProperties({
                    layer: $scope.layer,
                    CdoId: $scope.layer.CdoId,
                    SpecId: !_.isNull($scope.spec) ? $scope.spec.SpecId : null,
                    ProjectHandle: projectHandle,
                    Handle: -1,
                });

                // Add feature to snap control
                if (!_.isNull($scope.interactions.snap))
                    $scope.interactions.snap.addFeature(event.feature, true);

                // Add to indexedDB
                if (_.isNull($rootScope.newTask)) {

                    // Is application online?
                    if ($rootScope.applicationOnline) {

                        // Find DisplayFields in Activity
                        if (!_.isNull($scope.layer.DisplayFields)) {
                            // Add annotation                        
                            $scope.annotation(event.feature).then(function (feature) {
                                // Do nothing
                            }, function (feature) {
                                // Remove selected feature from layer
                                $scope.vector.getSource().removeFeature(event.feature);
                            });

                        } else {
                            // Add to DB
                            _geometry.insertIndexedDB(event.feature).then(function () {
                                // Update dirty
                                $rootScope.$broadcast('application:tasks:dirty');
                            });
                        }
                    } else {
                        // Add to DB
                        _geometry.insertIndexedDB(event.feature).then(function () {
                            // Update dirty
                            $rootScope.$broadcast('application:tasks:dirty');
                        });
                    }
                }

                // If newTask is defined
                if (!_.isNull($rootScope.newTask) && $scope.page.disabled) {

                    // Set task id
                    event.feature.setProperties({
                        taskId: $rootScope.newTask.TaskId
                    });

                    // Set coordinates
                    $rootScope.newTask.Coordinates = _geometry.toPoints(event.feature);

                    // Add marker to map
                    _map.marker.add(ol.proj.fromLonLat([$rootScope.newTask.Coordinates[0].Longitude, $rootScope.newTask.Coordinates[0].Latitude]), _map.map.getView().getZoom(), { center: false });

                    // Make task fullscreen                    
                    $rootScope.$broadcast('application:draw:stop');
                }

            });

            // Add interaction draw
            _map.map.addInteraction($scope.interactions.draw);
            // Add interaction snap
            if (!_.isNull($scope.interactions.snap))
                _map.map.addInteraction($scope.interactions.snap);
        }

        // Move feature to GPS position
        $scope.moveToGpsPosition = function () {
            $scope.moveToGpsPosition_ = true;
        }

        // Watch 'moveToGps'
        $scope.$watch(function () { return $scope.moveToGpsPosition_; }, function (newValue, oldValue) {

            if ($scope.moveToGpsPosition_ && !_.isNull($scope.interactions.select)) {

                // Stop move to GPS position
                $scope.moveToGpsPosition_ = false;

                // Selected feature
                var feature = _.first($scope.selected);
                var geometry = feature.getGeometry();

                if (geometry.getType() == "Point") {
                    feature.setGeometry(new ol.geom.Point(_gps.position()));
                }

                if (geometry.getType() == "LineString" || geometry.getType() == "Polygon") {

                    var coordinates = geometry.getCoordinates();
                    var constructor = null;

                    if (geometry.getType() == "LineString") {
                        constructor = ol.geom.LineString;
                    }

                    // If polygon flatten coordinates
                    if (geometry.getType() == "Polygon") {
                        constructor = ol.geom.Polygon;
                        coordinates = _.flatten(coordinates);
                    }

                    // Calculate distances to points
                    var points = _.sortBy(_.map(coordinates, function (coordinate, index) {
                        var line = new ol.geom.LineString([coordinate, $scope.selectedEvent.coordinate]);
                        var distance = (line.getLength() * 100) / 100;
                        return { index: index, distance: distance };
                    }), 'distance');

                    // Get closest point
                    var closest_point = _.first(points);
                    var next_closest_point = points[1];
                    if (!_.isUndefined(closest_point) && !_.isNull(closest_point)) {
                        if (closest_point.distance <= 10) {
                            coordinates[closest_point.index] = _gps.position();
                        } else {
                            var idx = closest_point.index;
                            if (next_closest_point.index > idx)
                                idx++;
                            coordinates.splice(idx, 0, _gps.position());
                        }

                        if (geometry.getType() == "LineString") {
                            geometry = new ol.geom.LineString(coordinates, 'XY');
                            feature.setGeometry(geometry);
                        }

                        if (geometry.getType() == "Polygon") {
                            geometry = new ol.geom.Polygon([coordinates], 'XY');
                            feature.setGeometry(geometry);
                        }
                    }
                }

                // Add to DB
                _geometry.updateIndexedDB(feature).then(function () {
                    // Update dirty
                    $rootScope.$broadcast('application:tasks:dirty');
                });
            }
        });

        // Commit
        $scope.commit = function () {
            // Stop interactions
            $scope.stop();
            // Show loading
            $rootScope.$broadcast('loading:show');
            // Sync task and geometry
            _sync.geometry().then(function () {
                // Hide loading
                $rootScope.$broadcast('loading:hide');
            });
            // Done sync
            $rootScope.$broadcast('application:data:synced');
        }

        // Dismiss
        $scope.dismiss = function () {
            // Stop interactions
            $scope.stop();
            // Clear geometries
            _geometry.clearDirtyIndexedDB().then(function () {
                // Reload from cache
                $scope.$parent.reload().then(function () {
                    // Update dirty
                    $rootScope.$broadcast('application:tasks:dirty');

                })
            });
            // Done sync
            $rootScope.$broadcast('application:data:synced');
        }

        // Remove
        $scope.remove = function () {
            // Selected features
            _.each($scope.selected, function (feature) {
                // Remove selected feature from layer
                $scope.vector.getSource().removeFeature(feature);
                // Remove from indexedDB                    
                if (_.isNull($rootScope.newTask))
                    _db.geometries.remove(feature.get('key'));
                // If newTask is defined
                if (!_.isNull($rootScope.newTask))
                    $rootScope.newTask.Coordinates = null;
            });
            // Clear
            $scope.selected = [];
            // Remove
            $scope.interactions.select.getFeatures().clear();
            // Update dirty
            $rootScope.$broadcast('application:tasks:dirty');
        }

        // Stop interactions
        $scope.stop = function () {

            if (!_.isNull($scope.interactions)) {

                // Select interaction
                if (!_.isNull($scope.interactions.select)) {
                    // Clear selected features
                    $scope.interactions.select.getFeatures().clear();
                    // Clear selected
                    $scope.selected = [];
                }

                // Remove interaction
                _map.map.removeInteraction($scope.interactions.draw);
                _map.map.removeInteraction($scope.interactions.select);
                _map.map.removeInteraction($scope.interactions.modify);
                _map.map.removeInteraction($scope.interactions.snap);

                // Remove click
                if (!_.isNull($scope.singleclick))
                    _map.map.unByKey($scope.singleclick);
                $scope.singleclick = null;

                // Remove interaction
                $scope.interactions.draw = null;
                $scope.interactions.select = null;
                $scope.interactions.modify = null;
                $scope.interactions.snap = null;
            }
            // Clear state
            $scope.state = null;
        }

        // Annotation
        $scope.annotation = function (feature) {
            // Deferrer
            var deferred = $q.defer();
            if (!_.isUndefined(feature) && !_.isNull(feature)) {

                // If not set then get it from API
                if (_.isUndefined(feature.get('DisplayFields')))
                    feature.set('DisplayFields', angular.copy($scope.layer.DisplayFields));
                if (_.isUndefined(feature.get('Files')))
                    feature.set('Files', []);

                // Display dialog
                _annotation.dialog(feature).result.then(function (result) {
                    // Add displayFields to feature
                    feature.set('DisplayFields', result.displayFields);
                    // Add files to feature
                    feature.set('Files', result.files);
                    // UpSert to DB
                    _geometry.upSertIndexedDB(feature).then(function () {
                        // Resolve
                        deferred.resolve(feature);
                        // Update dirty
                        $rootScope.$broadcast('application:tasks:dirty');
                    });
                }, function () {
                    // Cancel
                    deferred.reject(feature);
                });
            }
            return deferred.promise;
        }

        // Clear features drawn for newTask
        $scope.clearFeaturesForNewTask = function () {
            // Remove features
            _.each($scope.vector.getSource().getFeatures(), function (feature) {
                if (!_.isUndefined(feature.get('taskId')) && !_.isNull(feature.get('taskId')))
                    $scope.vector.getSource().removeFeature(feature);
            });
        }

        // Watch selected project.
        $scope.$watch(function () { return $localStorage.defaultProject; }, function (newValue, oldValue) {
            // Stop draw
            if ($scope.state == 'draw') {
                // Stop interactions
                $scope.stop();
                // Enable select
                $scope.select();
                return;
            }
        });

        // Watch state
        $scope.$watch(function () { return $scope.state; }, function (newValue, oldValue) {
            // Move to GPS allowed            
            $scope.buttons.moveToGps = ($scope.state == 'draw' && $scope.interactions.draw.type_ != 'Point' && !_.isUndefined(_gps.position()) && !_.isNull(_gps.position()));
        });

        // Watch selected task
        $scope.$watch(function () { return $rootScope.selectedTask; }, function (newValue, oldValue) {
            if ($scope.state == 'draw' && ($rootScope.selectedTask === undefined || $rootScope.selectedTask == null) && ($localStorage.defaultProject === undefined || $localStorage.defaultProject == null)) {
                toastr.warning($filter('translate')('labels.usingdefaultprojecthandlenoproject'));
            }
        });

        // Watch MapModus
        $scope.$watch(function () { return $rootScope.mapMode; }, function (newValue, oldValue) {

            // Disable draw
            if ($rootScope.mapMode.Value != "drawmode") {
                // Stop
                $scope.stop();
            }
            // Enable draw
            if ($rootScope.mapMode.Value == "drawmode") {
                // Buttons status
                $scope.setButtons();
                // Select
                if (!_.isNull($scope.layer))
                    $scope.select();
            }
        }, true);

        // Watch selected
        $scope.$watch(function () { return $scope.selected; }, function (newValue, oldValue) {
            // Delete allowed
            $scope.buttons.delete = $scope.selected.length > 0 && $scope.selected[0].get('Handle') == '-1';
            // Annotation allowed
            $scope.buttons.annotation = $rootScope.applicationOnline && !_.isNull($scope.layer) && !_.isNull($scope.layer.DisplayFields) && $scope.layer.DisplayFields.length > 0 && !_.isNull($scope.selected) && $scope.selected.length > 0;
            // Move to GPS allowed
            if ($scope.interactions.select != null && !_.isUndefined(_gps.position()) && !_.isNull(_gps.position()))
                $scope.buttons.moveToGps = $scope.selected.length > 0;
        });

        // Watch localStorage.cdotypes
        $scope.$watch(function () { return $localStorage.cdotypes; }, function (newValue, oldValue) {
            $scope.layers = _.filter($localStorage.map.layers, function (layer) {
                return !_.isUndefined(_.find($localStorage.cdotypes, { 'CdoId': layer.CdoId, 'Visible': true }));
            });
        }, true);

        // Watch layer, select Specs
        $scope.$watch(function () { return $scope.layer; }, function (newValue, oldValue) {

            $scope.spec = null;
            $scope.specs = [];

            // GpsTrack button
            $scope.buttons.gpsTrack = $scope.layer != null && $scope.layer.CdoId == $rootScope.settings.gpsTrackLayer && !_.isUndefined(_gps.position()) && !_.isNull(_gps.position());

            if (!_.isNull($scope.layer)) {
                // Specs
                $scope.specs = _.filter($localStorage.specs, { CdoId: $scope.layer.CdoId });
                if ($scope.specs.length == 0)
                    $scope.specs = _.filter($localStorage.specstable, { CdoId: $scope.layer.CdoId });
                // First spec
                if ($scope.specs.length > 0)
                    $scope.spec = _.first($scope.specs);
            }
        });

        // Watch settings 'drawUseSnap'
        $scope.$watch(function () { return $rootScope.settings.drawUseSnap; }, function (newValue, oldValue) {

            if ($scope.state == 'draw') {
                // Stop interaction
                $scope.stop();
                // Start interaction
                $scope.draw();
            }

            if ($scope.state == 'select') {
                // Stop interaction
                $scope.stop();
                // Start interaction
                $scope.select();
            }
        });

        // Watch 'online/offline'
        $scope.$watch(function () { return $rootScope.applicationOnline; }, function (newValue, oldValue) {
            // Annotation allowed
            $scope.buttons.annotation = $rootScope.applicationOnline && !_.isNull($scope.layer) && !_.isNull($scope.layer.DisplayFields) && $scope.layer.DisplayFields.length > 0 && !_.isNull($scope.selected) && $scope.selected.length > 0;
        });

        // Draw stop
        $rootScope.$on('application:draw:stop', function ($event, args) {
            // Enable toolbar
            $scope.$parent.page.disabled = false;
            // Clear features
            $scope.clearFeaturesForNewTask();
            // Clear layer
            $scope.layer = null;
            // Select mapmode 'mapmode'
            $scope.selectMapMode(null, _.find($scope.mapModes, { Value: 'mapmode' }));
        });

        // Draw start
        $rootScope.$on('application:draw:start', function ($event, args) {
            // Draw layer
            var layer = _.find($localStorage.map.layers, { 'CdoId': $rootScope.selectedTask.Activity.CdoId });
            // Only if coordinates is null
            if (!_.isUndefined(layer)) {
                // Disable toolbar
                $scope.$parent.page.disabled = true;
                // Select mapmode 'drawmode'
                $scope.selectMapMode(null, _.find($scope.mapModes, { Value: 'drawmode' }));
                // Select layer by CdoId
                $scope.selectLayer(null, layer);
            }
        });

        // Data committed
        $rootScope.$on('application:data:synced', function () {
            // Check buttons
            $scope.setButtons();
            // Stop interactions
            $scope.stop();
            // Enable draw
            if ($rootScope.mapMode.Value == "drawmode") {
                // Buttons status
                $scope.setButtons();
                // Select
                if (!_.isNull($scope.layer))
                    $scope.select();
            }
        });

        // On reload data
        $rootScope.$on('application:map:load', function () {
            // Check buttons
            $scope.setButtons();
            // Stop interactions
            $scope.stop();
            // Select
            if (!_.isNull($scope.layer))
                $scope.select();
        });

        // On destroy panel
        $scope.$on('$destroy', function () {
            // Destroy events
            $scope.stop();
        });
    }]);

}());