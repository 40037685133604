(function () {

    // Strict
    'use strict';
    
    angular.module('app').controller('customview.gebruiksdoel.controller', ['$rootScope', '$scope', '$compile', function ($rootScope, $scope, $compile) {

        $scope.label = '';
        $scope.handle = '';

        // Watch feature
        $scope.$watch(function () { return $scope.$parent.feature; }, function (newValue, oldValue) {
            if (!_.isNull($scope.$parent.feature)) {
                // Geoline
                var geoline = $scope.$parent.feature.get('geoline');

                // Set Label
                $scope.label = geoline.Label;
            }
        });

    }]);

}());