(function () {

    // Strict
    'use strict';

    angular.module('app').controller('customview.aansluiten.controller', ['$rootScope', '$scope', '$compile', '$filter', '$confirm', '$timeout', '$localStorage', 'api.factory', 'log.factory', function ($rootScope, $scope, $compile, $filter, $confirm, $timeout, $localStorage, _api, _log) {

        $scope.label = '';
        $scope.spec = null;
        $scope.specValues = [];
        $scope.handle = '';

        // Select Spec
        $scope.select = function ($event, spec) {
            // Set spec
            $scope.spec = spec;
            // Confirm
            $confirm({ title: $filter('translate')('labels.changespectitle'), text: $filter('translate')('labels.changespecmessage') }).then(function () {
                // Show loading
                $rootScope.$broadcast('loading:show');
                // Handle
                var handle = $scope.$parent.feature.get('geoline').Handle;
                _api.setObjectSpec(handle, $scope.spec.SpecId).then(function (result) {
                    // Close popup
                    $scope.close();
                    // Reload geometry
                    $rootScope.$broadcast('application:map:load');
                }, function (error) {
                    // Write to log
                    _log.error("aansluiten.controller", "api.setObjectSpec", error);
                }).finally(function () {
                    // Hide loading
                    $rootScope.$broadcast('loading:hide');
                });
            });
        };

        // Watch feature
        $scope.$watch(function () { return $scope.$parent.feature; }, function (newValue, oldValue) {

            if (!_.isNull($scope.$parent.feature)) {

                // Geoline
                var geoline = $scope.$parent.feature.get('geoline');

                // SpecValues
                $scope.specValues = _.filter($localStorage.specs, { CdoId: geoline.CdoId });
                
                // SpecTable
                if ($scope.specValues.length == 0)
                    $scope.specValues = _.filter($localStorage.specstable, { CdoId: geoline.CdoId });
                
                // Set Spec
                $scope.spec = _.find($scope.specValues, { SpecId: $scope.$parent.feature.get('SpecId') });
                                
                // Set Label
                $scope.label = geoline.Label;
            }
        });

    }]);

}());