(function () {

    // Strict
    'use strict';

    // Controller
    angular.module('app').controller('toolbar.controller', ['$rootScope', '$scope', '$filter', '$sessionStorage', '$localStorage', 'toastr', 'map.factory', 'api.factory', 'log.factory', 'db.factory', 'gps.service', function ($rootScope, $scope, $filter, $sessionStorage, $localStorage, toastr, _map, _api, _log, _db, _gps) {

        // Settings
        $scope.page = {
            toolbar: {
                collapse: false
            },
            search: {
                collapse: false
            },
            disabled: false
        }
        
        // Refresh
        $scope.refresh = function () {
            // Load geometry
            $rootScope.$broadcast('application:map:load');
        }

        // Zoom
        $scope.zoom = function (options) {
            // Map View
            var view = _map.map.getView();

            // Zoom Animation
            _map.map.beforeRender(ol.animation.zoom({ duration: 500, resolution: view.getResolution() }));

            // Zoom
            view.setZoom(view.getZoom() + options.direction);
        }

        // Home
        $scope.buttons = { home: false };
        $scope.home = function () {
            _map.map.getView().setCenter(_gps.position()); 
        }

        $rootScope.$on("application:gps:position", function () {
            $scope.buttons.home = !_.isUndefined(_gps.position()) && !_.isNull(_gps.position());            
        });

        // End Events

        // Mapmodus
        $scope.mapModes = [
                { Value: 'mapmode', Icon: 'fa-map-o', Enabled: true },
                { Value: 'drawmode', Icon: 'fa-pencil-square-o', Enabled: $scope.settings.subMenuSettings.drawModeEnabled },
        ];

        // MapMode
        $rootScope.mapMode = _.first($scope.mapModes);

        // Select Mapmode
        $scope.selectMapMode = function ($event, mapMode) {
            if (!mapMode.Enabled) {
                $event.stopPropagation();
                $event.preventDefault();
                return false;
            }
            // Close search
            $scope.page.search.collapse = false;
            // Change mapMode
            $rootScope.mapMode = mapMode;
        }

        // CustomView
        $scope.customViews = $localStorage.customviews;

        // Set default value
        if (_.isUndefined($rootScope.customView))
            $rootScope.customView = _.first($scope.customViews);
        
        // Select CustomView
        $scope.selectCustomView = function ($event, customView) {
            $rootScope.customView = customView;
        }

        // Watch online/offline
        $scope.$watch(function () { return $rootScope.applicationOnline; }, function (newValue, oldValue) {
            if (!$rootScope.applicationOnline && $scope.page.search.collapse)
                $scope.page.search.collapse = false;
        });
    }]);

}());