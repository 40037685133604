(function () {

    // Strict
    'use strict';
    
    angular.module('app').directive('popover', ['$rootScope', 'map.factory', function ($rootScope, _map) {
        return {
            restrict: 'E',
            replace: true,
            transclude: true,
            templateUrl: './app/directives/popover.directive.html',
            controller: ['$rootScope', '$scope', '$element', function ($rootScope, $scope, $element) {

                // Variables
                $scope.feature = null;
                $scope.title = null;
                $scope.customview = null;

                // Init
                $scope.init = function () {
                    // Overlay
                    $scope.overlay = new ol.Overlay({
                        element: $element[0],
                        positioning: 'bottom-center',
                        stopEvent: false,
                        autoPan: true,
                        autoPanAnimation: {
                            duration: 250
                        }
                    });
                    _map.map.addOverlay($scope.overlay);
                }

                // Open
                $scope.open = function (event) {

                    // Load feature from event (pixel)
                    var feature = _map.map.forEachFeatureAtPixel(event.pixel, function (feature, layer) {
                        return feature;
                    });

                    // No feature or same feature
                    if (_.isUndefined(feature) || _.isNull(feature) || feature == $scope.feature)
                        return;

                    var geoline = feature.get('geoline');
                    if (!_.isUndefined(geoline) && (geoline.ViewId == "status" || geoline.ViewId == "type")) {
                        $scope.$apply(function () {
                            // Set feature
                            $scope.feature = feature;
                            // Title
                            $scope.title = geoline.ViewLabel;
                            // Load template
                            switch (geoline.ViewId) {
                                case "status":
                                    $scope.customview = 'app/customview/customview.gebruiksdoel.view.html';
                                    break;
                                case "type":
                                    $scope.customview = 'app/customview/customview.aansluiten.view.html';
                                    break;
                            }
                            // Show popup
                            $scope.show(event);
                        });
                    }
                }

                // Show
                $scope.show = function (event) {
                    // Set position
                    $scope.overlay.setPosition(event.coordinate);
                }

                // Close
                $scope.close = function ($event) {
                    // Hide overlay
                    $scope.overlay.setPosition(undefined);
                    // Reset variables
                    $scope.feature = null;
                    $scope.title = null;
                    $scope.customview
                }

                // Init 
                $scope.init();
            }],
            link: function ($scope, element, attrs) {
                // Map singleclick
                _map.map.on('singleclick', $scope.open);
                // Close popup
                $rootScope.$on('application:popup:remove', $scope.close);
            }
        }
    }]);
}());