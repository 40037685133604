(function () {
    'use strict';

    angular.module('app').factory('cache.service', ['$rootScope', '$localStorage', '$filter', '$q', 'api.factory', 'db.factory', 'log.factory', 'toastr', function ($rootScope, $localStorage, $filter, $q, _api, _db, _log, toastr) {
        return {
            reset: function () {
                // Clear saved cdoTypes
                $localStorage.cdotypes = [];
                // Load cache
                return this.load();
            },
            load: function () {

                // Defer
                var deferred = $q.defer();

                // Requests
                var requests = [];

                // Load MapSettingsFileJson
                requests.push(_api.getMapsettingsFile().then(function (iml) {

                    // MapSettingsFile to localStorage
                    $localStorage.map = { layers: iml.Layers, trenchContentLayer: iml.TrenchContentLayerName };

                    // Copy CdoTypes to localStorage
                    var cdotypes = $localStorage.cdotypes;
                    $localStorage.cdotypes = [];
                    _.each($localStorage.map.layers, function (layer) {
                        var visible = layer.IsVisible;
                        var cdotype = _.find(cdotypes, { CdoId: layer.CdoId });
                        if (!_.isUndefined(cdotype)) {                            
                            visible = cdotype.Visible;
                        }
                        $localStorage.cdotypes.push({
                            Name: layer.Name,
                            CdoId: layer.CdoId,
                            Visible: visible,
                            Labels: _.map(layer.LabelStyles, 'FieldName'),
                            ShowLabels: layer.ShowLabels
                        });
                    });

                }, function (error) {
                    // Write to log
                    _log.error("login", "login.controller.getMapsettingsFile", error);
                    // End loading
                    $rootScope.$broadcast("loading:hide");
                    // Show modal
                    toastr.error($filter('translate')('Could not retrieve map information, please try again later'));
                }));

                // Load SpecsTable
                requests.push(_api.getSpecsTable().then(function (result) {
                    // Add spectable to localStorage
                    $localStorage.specstable = result.data;
                }, function (error) {
                    // Write to log
                    _log.error("login.controller", "getSpecsTable", error);
                }));

                // Load activities
                requests.push(_api.getActivities().then(function (result) {
                    // Add activities to localStorage
                    $localStorage.activities = result.data;
                }, function (error) {
                    // Write to log
                    _log.error("login.controller", "getActivities", error);
                }));

                // Load CustomView
                requests.push(_api.getCustomViews().then(function (result) {
                    // CustomViews
                    var customviews = result.data;
                    // CustomView 'Normal'
                    customviews.unshift({ ViewLabel: $filter('translate')('labels.normal'), ViewId: '' });
                    // Add customViews to localStorage
                    $localStorage.customviews = customviews;
                }, function (error) {
                    // Write to log
                    _log.error("login.controller", "getCustomViews", error);
                }));

                // Load Specs
                requests.push(_api.getSpecs().then(function (result) {
                    // Specs
                    var specs = result.data;
                    // Add specs to localStorage
                    $localStorage.specs = specs;
                }, function (error) {
                    // Write to log
                    _log.error("login.controller", "getSpecs", error);
                }));

                // Run all requests
                $q.all(requests).then(function () {
                    deferred.resolve();
                });

                // Return promise
                return deferred.promise;
            }
        }

    }]);
})();