(function () {
    'use strict';

    angular.module('app').factory('file.service', ['$rootScope', '$q', 'toastr', function ($rootScope, $q, toastr) {

        var extensions = [
            { fa: 'fa-file-word-o', types: ['docx', 'doc'] },
            { fa: 'fa-file-excel-o', types: ['xlsx', 'xls'] },
            { fa: 'fa-file-pdf-o', types: ['pdf'] },
            { fa: 'fa-file-image-o', types: ['png', 'jpg', 'jpeg', 'gif', 'bmp'] }
        ];

        return {
            findIcon: function (extension) {
                // Find icon
                return _.find(extensions, function (ext) {
                    return _.indexOf(ext.types, extension.replace(".", "")) > -1;
                });
            },
            icon: function (extension) {

                // Find icon
                var icon = this.findIcon(extension);

                // Use icon, if not found use default
                if (!_.isUndefined(icon) && !_.isNull(icon)) {
                    return icon.fa;
                } else {
                    return 'fa-file-o';
                }
            },
            add: function () {  

                var self = this;

                // Deferred
                var deferred = $q.defer();
                // Create input
                self.input = document.createElement('input');
                self.input.type = 'file';
                self.input.onchange = function () {                    
                    if (self.input.files.length > 0) {
                        // Upload file
                        var f = self.input.files[0], r = new FileReader();

                        // File
                        var file = { Handle: -1, Extension: extension(f.name.toLowerCase()), Data: null, Name: f.name };

                        // Is file allowed
                        if (_.isUndefined(self.findIcon(file.Extension))) {
                            // Display error
                            toastr.warning('File not allowed');
                            // Rject
                            deferred.reject('File not allowed');
                        }

                        r.onloadend = function (e) {                           
                            // Set data
                            file.Data = e.target.result;
                            // Set model
                            deferred.resolve(file);                            
                        }
                        r.readAsDataURL(f);
                    }
                };
                self.input.click();
                return deferred.promise;
            }
        }
    }]);
})();