(function () {
    'use strict';

    angular.module('app').directive('dateTimeNow', ['$timeout', function ($timeout) {
        return {
            require: 'ngModel',
            link: function ($scope, element, attrs, ctrl) {
                element.bind('click', function () {
                    ctrl.$setViewValue(new Date());
                    ctrl.$render();
                });
            }
        }
    }]);

})();