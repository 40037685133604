
angular.module('app').factory('task.factory', ['$rootScope', '$q', '$localStorage', 'map.factory', 'db.factory', 'api.factory', function ($rootScope, $q, $localStorage, _map, _db, _api) {

    // Variables
    var self = {};

    return {
        dirty: dirty,
        commit: commit,
        subtasksFinished: subtasksFinished
    }

    // Commit tasks
    function commit() {
        // Defer
        var defer = $q.defer();       

        // Get all tasks from indexedDB with Dirty=true
        dirty().then(function (tasks) {
            // Commit tasks
            _api.commitTasks(tasks).then(function (result) {
                // Resolve
                defer.resolve(result);
            });
        }, function (error) {
            // Error
            defer.resolve({ error: true });
        });

        // Return promise
        return defer.promise;
    }

    // All subtasks finished?
    function subtasksFinished(task) {
        var subtasks = [];
        return (function load(task) {            
            return _db.tasks.all().then(function (tasks) {
                var deferred = $q.defer();
                tasks = _.filter(tasks, { ParentTaskId: task.TaskId });
                if (tasks.length > 0) {
                    return $q.all(tasks.map(function (subtask) {
                        subtasks.push(subtask);                        
                        return load(subtask);
                    }));
                }
            });            
        }(task)).then(function () {
            return (_.filter(subtasks, { Finished: false }).length == 0);
        });
    };

    // Any task dirty?
    function dirty() {
        return _db.tasks.all().then(function (tasks) {
            return _.filter(tasks, { Dirty: true });
        });
    }
}]);